import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import nunjucks from 'nunjucks';
import Excel from 'shared/Excel';
import {
    generateDoorPosition,
    generateReturnDoor,
    getDoorFormValues,
    generateApplianceDoor,
    generateDoorDrawer,
} from 'components/customer/Preview3DCommon/values/door';
import {
    getDefaultFormValues,
    getDefaultValues,
} from 'components/customer/Preview3DCommon/values/default';
import {
    generateDrawerPosition,
    getDrawerFormValues,
    generateInnerDrawer,
} from 'components/customer/Preview3DCommon/values/drawer';
import {
    getShelvesFormValues,
    generateHorizontalShelves,
    generateComplexHorizontalShelves,
    generateComplexVerticalShelves,
    generateUpperAndLowerShelves,
    generateUpperAndLowerLeftRightShelves,
    generateKickerLadderFrames,
    generateBulkheadLadderFrames,
} from 'components/customer/Preview3DCommon/values/shelves';
import {
    generateMultiPart,
    getMultipartFormValues,
} from 'components/customer/Preview3DCommon/values/multipart';
import {
    getRangehoodFormValues,
    generateRangehoodValues,
} from 'components/customer/Preview3DCommon/values/rangehood';
import {
    generateWallOvenValues,
    getWallOvenFormValues,
} from 'components/customer/Preview3DCommon/values/wallOven';
import {RoomPlannerFormValues} from 'components/customer/RoomPlanner/types';

/* eslint-disable */
const useRoomPlannerData = () => {
    const replaceVariables = (
        jsonString: string,
        replacements: TemplateVariable
    ): string => {
        const renderedTemplate = nunjucks.renderString(
            jsonString,
            replacements
        );

        return renderedTemplate;
    };

    const replaceDimensions = (
        json: string,
        values: RoomPlannerFormValues,
        variables: TemplateVariable
    ) => {
        const hasDoor = typeof values.cabinet_door_gap !== 'undefined';
        const carcaseThickness = values.cabinet_carc_thickness;
        const exteriorThickness = values.cabinet_ext_thickness;

        const doorFormValues = getDoorFormValues(values);
        const drawerFormValues = getDrawerFormValues(values);
        const shelvesFormValues = getShelvesFormValues(
            values,
            variables?.shelfType
        );
        const multipartFormValues = getMultipartFormValues(values);
        const rangehoodFormValues = getRangehoodFormValues(values);
        const wallOvenFormValues = getWallOvenFormValues(values);

        const doorValues = {
            doorDefault: hasDoor
                ? generateDoorPosition(variables, values, doorFormValues)
                : {},
            doorWithReturn: variables.isReturnProduct
                ? generateReturnDoor(variables, values, doorFormValues)
                : {},
            applianceDoor:
                variables.isApplianceBifoldProduct ||
                Boolean(
                    variables?.fields.find(
                        (field) => field.name === 'isApplianceRollUp'
                    )?.expression
                )
                    ? generateApplianceDoor(variables, values, doorFormValues)
                    : {},
            doorDrawer:
                doorFormValues.drawers &&
                doorFormValues.drawers.length > 0 &&
                hasDoor
                    ? generateDoorDrawer(doorFormValues)
                    : {},
        };

        const drawerValues = {
            drawerDefault: generateDrawerPosition(
                values,
                variables,
                drawerFormValues,
                // Move the checking into the variables
                hasDoor &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    values.template_3d[0]?.attributes.template !=
                        'BaseDrawers.xml'
            ),
            innerDrawers: Boolean(variables.innerDrawerCount)
                ? generateInnerDrawer(values, variables, drawerFormValues)
                : {},
        };

        const shelvesValues = {
            simpleShelves:
                typeof values.shelves !== 'undefined'
                    ? generateHorizontalShelves(
                          values,
                          shelvesFormValues,
                          variables
                      )
                    : {},
            complexHorizontalShelves: Boolean(
                shelvesFormValues?.shelvesHorizontalCount
            )
                ? generateComplexHorizontalShelves(values, shelvesFormValues)
                : {},
            complexVerticalShelves: Boolean(
                shelvesFormValues?.shelvesVerticalCount
            )
                ? generateComplexVerticalShelves(values, shelvesFormValues)
                : {},
            pantryShelves:
                Boolean(values?.upper_shelves) &&
                variables?.shelfType === 'PANTRY'
                    ? generateUpperAndLowerShelves(values, shelvesFormValues)
                    : {},
            broomShelves:
                Boolean(values?.upper_shelves) && Boolean(values?.shelves)
                    ? generateUpperAndLowerLeftRightShelves(
                          values,
                          shelvesFormValues,
                          variables
                      )
                    : {},
            kicker: Boolean(values.ladder_frames)
                ? generateKickerLadderFrames(values, shelvesFormValues)
                : {},
            bulkhead: Boolean(values.ladder_frames)
                ? generateBulkheadLadderFrames(values, shelvesFormValues)
                : {},
        };

        const multipartValues = {
            multiPart:
                Boolean(multipartFormValues.microwaveOpeningHeight) ||
                Boolean(values?.upper_shelves && values?.lower_shelves)
                    ? generateMultiPart(values, multipartFormValues)
                    : {},
        };

        const rangehoodValues = {
            rangehood:
                variables?.hasRangehoodVent ||
                typeof values.rangehood_opening_height !== 'undefined'
                    ? generateRangehoodValues(values, rangehoodFormValues)
                    : {},
        };

        const wallOvenValues = {
            wallOven: variables?.isWallOvenProduct
                ? generateWallOvenValues(values, wallOvenFormValues)
                : {},
        };

        const formValues: any = {
            default: getDefaultValues(values, getDefaultFormValues(values)),
            ...doorValues,
            ...drawerValues,
            ...shelvesValues,
            ...multipartValues,
            ...rangehoodValues,
            ...wallOvenValues,
            ...values,
        };

        variables.fields.forEach((f) => {
            const evaluated = Excel.calculate(f.expression, {
                ...values,
                exteriorThickness: exteriorThickness,
                carcaseThickness: carcaseThickness,
            });
            formValues[f.name as string] = evaluated;
        });

        return replaceVariables(json, {...formValues});
    };

    return {
        replaceDimensions,
    };
};

export default useRoomPlannerData;
