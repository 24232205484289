import {MutableRefObject} from 'react';
import * as THREE from 'three';

export const getGroupWidth = (group: THREE.Group<THREE.Object3DEventMap>) => {
    const boundingBox = new THREE.Box3().setFromObject(group);

    const width = boundingBox.max.x - boundingBox.min.x;
    const height = boundingBox.max.y - boundingBox.min.y;
    const depth = boundingBox.max.z - boundingBox.min.z;

    return {width, height, depth};
};

export const getDirection = (rotation: number) => {
    const normalizedRotation =
        ((rotation % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);

    if (Math.abs(normalizedRotation) < 0.1) return 'normal';
    if (Math.abs(normalizedRotation - Math.PI / 2) < 0.1) return 'right';
    if (Math.abs(normalizedRotation - Math.PI) < 0.1) return 'back';
    if (Math.abs(normalizedRotation - (3 * Math.PI) / 2) < 0.1) return 'left';
    return 'normal';
};

export const handleSnapping = (
    cabinetGroup: THREE.Group<THREE.Object3DEventMap>,
    targetPos: THREE.Vector3,
    productsRef: MutableRefObject<THREE.Group<THREE.Object3DEventMap>[]>
) => {
    const snapPosition = targetPos.clone();
    let snapped = false;
    let snapDistance = 120;
    const {width: latestWidth, depth: latestDepth} =
        getGroupWidth(cabinetGroup);

    productsRef.current?.forEach((otherProduct) => {
        if (otherProduct.userData.id == cabinetGroup.userData.id) return;
        const box = new THREE.Box3().setFromObject(otherProduct);
        const size = new THREE.Vector3();
        box.getSize(size);

        const directions = [
            new THREE.Vector3(1, 0, 0), // Right
            new THREE.Vector3(-1, 0, 0), // Left
            new THREE.Vector3(0, 0, 1), // Forward
            new THREE.Vector3(0, 0, -1), // Backward
        ];

        directions.forEach((dir, index) => {
            const axis = dir.clone().multiply(size);
            const proposedPos = otherProduct.position.clone().add(axis);
            const selectedCabinetDirection = getDirection(
                cabinetGroup.rotation.z
            );

            const otherCabinetDirection = getDirection(otherProduct.rotation.z);
            const cabinetRotations = {
                selected: {
                    isLeft: selectedCabinetDirection === 'left',
                    isRight: selectedCabinetDirection === 'right',
                    isBack: selectedCabinetDirection === 'back',
                    isNormal: selectedCabinetDirection === 'normal',
                },
                other: {
                    isLeft: otherCabinetDirection === 'left',
                    isRight: otherCabinetDirection === 'right',
                    isBack: otherCabinetDirection === 'back',
                    isNormal: otherCabinetDirection === 'normal',
                },
            };
            let isBackToBack = false;
            const backToBackData = {
                isFrontAndBack: false,
            };
            let allowDirections = [0, 1];

            if (
                (cabinetRotations.selected.isBack &&
                    cabinetRotations.other.isNormal) ||
                (cabinetRotations.other.isBack &&
                    cabinetRotations.selected.isNormal)
            ) {
                isBackToBack = true;
                backToBackData.isFrontAndBack = true;
                snapDistance = 135;
            }

            if (
                (cabinetRotations.selected.isLeft &&
                    cabinetRotations.other.isRight) ||
                (cabinetRotations.other.isLeft &&
                    cabinetRotations.selected.isRight)
            ) {
                isBackToBack = true;
                backToBackData.isFrontAndBack = false;
                snapDistance = 135;
            }

            let distancePos = targetPos.clone();

            if (isBackToBack) {
                if (
                    !backToBackData.isFrontAndBack &&
                    cabinetRotations.selected.isRight
                ) {
                    distancePos = new THREE.Vector3(
                        targetPos.x - latestWidth / 2,
                        targetPos.y,
                        targetPos.z - latestDepth / 2
                    );
                }

                if (
                    !backToBackData.isFrontAndBack &&
                    cabinetRotations.selected.isLeft
                ) {
                    distancePos = new THREE.Vector3(
                        targetPos.x + latestWidth / 2,
                        targetPos.y,
                        targetPos.z + latestDepth
                    );
                }

                if (
                    backToBackData.isFrontAndBack &&
                    cabinetRotations.selected.isNormal
                ) {
                    distancePos = new THREE.Vector3(
                        targetPos.x + latestWidth,
                        targetPos.y,
                        targetPos.z - latestDepth / 2
                    );
                }

                if (
                    backToBackData.isFrontAndBack &&
                    cabinetRotations.selected.isBack
                ) {
                    distancePos = new THREE.Vector3(
                        targetPos.x - latestWidth,
                        targetPos.y,
                        targetPos.z + latestDepth / 2
                    );
                }
            }

            const distance = proposedPos.distanceTo(distancePos);

            if (
                distance < snapDistance &&
                (selectedCabinetDirection === otherCabinetDirection ||
                    isBackToBack)
            ) {
                const snappedProduct = getGroupWidth(otherProduct);

                if (!isBackToBack) {
                    if (cabinetRotations.selected.isNormal) {
                        if (index === 0 || index === 1) {
                            proposedPos.z =
                                proposedPos.z -
                                (snappedProduct.depth - latestDepth);
                        }

                        if (index === 1) {
                            proposedPos.x =
                                proposedPos.x -
                                latestWidth +
                                snappedProduct.width;
                        }
                    } else if (cabinetRotations.selected.isRight) {
                        if (index === 2) {
                            proposedPos.z =
                                proposedPos.z -
                                (snappedProduct.depth - latestDepth);
                        }
                        allowDirections = [2, 3];
                    } else if (cabinetRotations.selected.isLeft) {
                        if (index === 3) {
                            proposedPos.z =
                                proposedPos.z -
                                (latestDepth - snappedProduct.depth);
                        }
                        allowDirections = [2, 3];
                    } else {
                        if (index === 0) {
                            proposedPos.x =
                                proposedPos.x +
                                latestWidth -
                                snappedProduct.width;
                        }
                    }
                } else {
                    if (index === 2) {
                        if (
                            backToBackData.isFrontAndBack &&
                            cabinetRotations.selected.isNormal
                        ) {
                            proposedPos.x = proposedPos.x - latestWidth;
                            proposedPos.z = proposedPos.z + latestDepth - 5;
                            snapped = index === 2;
                        }
                    }

                    if (index === 3) {
                        if (
                            backToBackData.isFrontAndBack &&
                            cabinetRotations.selected.isBack
                        ) {
                            proposedPos.x = proposedPos.x + latestWidth;
                            proposedPos.z = proposedPos.z - latestDepth + 5;
                            snapped = index === 3;
                        }
                    }

                    if (index === 0) {
                        if (
                            !backToBackData.isFrontAndBack &&
                            cabinetRotations.selected.isRight
                        ) {
                            proposedPos.x = proposedPos.x + latestWidth - 5;
                            proposedPos.z = proposedPos.z + latestDepth;
                            snapped = index === 0;
                        }
                    }

                    if (index === 1) {
                        if (
                            !backToBackData.isFrontAndBack &&
                            cabinetRotations.selected.isLeft
                        ) {
                            proposedPos.x = proposedPos.x - latestWidth + 5;
                            proposedPos.z = proposedPos.z - latestDepth;
                            snapped = index === 1;
                        }
                    }
                }

                snapPosition.copy(proposedPos);

                if (!isBackToBack) {
                    snapped = allowDirections.includes(index);
                }
            }
        });
    });

    return {
        snapPosition,
        snapped: cabinetGroup.userData.ignoreSnapping ? false : snapped,
        latestWidth,
        latestDepth,
    };
};
