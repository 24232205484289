import {useEffect, useRef, useState} from 'react';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {shallowEqual} from 'react-redux';
import * as THREE from 'three';
import {xml2json, json2xml} from 'xml-js';
import {Batch, PreviewPosition} from 'Preview3D/types';
import {loadDataXML} from 'components/customer/Preview3D/helpers';
import TWEEN from '@tweenjs/tween.js';
import {
    getRoomType,
    getRoomDimensions,
    getProducts,
    getShowCabinets,
    getProductValuesForUpdate,
    setProductValuesForUpdate,
    getSelectedProductValue,
    setSelectedProductValue,
    getPreviewPosition,
    getIsWireframeMode,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import useCameraSetup from 'components/customer/RoomPlanner/lib/useCameraSetup';
import useRendererSetup from 'components/customer/RoomPlanner/lib/useRendererSetup';
import useProductEvents from 'components/customer/RoomPlanner/lib/useProductEvents';
import useRoomLayout from 'components/customer/RoomPlanner/lib/useRoomLayout';
import useWallHider from 'components/customer/RoomPlanner/lib/useWallHider';
import useRoomPlannerData from 'components/customer/RoomPlanner/values/useRoomPlannerData';
import {createSceneXML} from 'components/customer/RoomPlanner/helpers/scene';
import useRoomProduct from 'components/customer/RoomPlanner/lib/useRoomProduct';
import useAdjustableLegs from 'components/customer/RoomPlanner/hardware/useAdjustableLegs';
import {useSearchParams} from 'react-router-dom';
import useAutoSave from './useAutoSave';
import {useDebouncedCallback} from 'use-debounce';
import {
    invalidateSidebarAndCost,
    useLazyGetProductDataQuery,
} from 'components/customer/Product/store/productApi';
import {useAddProductManually} from 'shared/helpers/addProductManually';
import {useJobContext} from 'contexts/JobContext';
import {PartialJob} from 'shared/types/PartialJob';
import {invalidateTotalProductCount} from 'components/customer/Job/store/jobApi';
import {
    RECENTLY_ADDED_KEYS,
    useRecentlyAddedActions,
} from 'hooks/RecentlyAdded';
import {useNotificationContext} from 'contexts';

/* eslint-disable */
const useRoomPlanner = () => {
    const dispatch = useAppDispatch();
    const scene = useRef<THREE.Scene>();
    const mountRef = useRef<HTMLDivElement>(null);
    const [showArrows, setShowArrows] = useState(false);
    const [dragPosition, setDragPosition] = useState<THREE.Vector3>(null);
    const roomType = useAppSelector(getRoomType);
    const [cabinetGroupList, setCabinetGroupList] = useState<
        THREE.Group<THREE.Object3DEventMap>[]
    >([]);
    const showCabinets = useAppSelector(getShowCabinets);
    const productsRef = useRef<THREE.Group<THREE.Object3DEventMap>[]>([]);
    const [reloader, setReloader] = useState(0);

    const roomPlannerData = useAppSelector(getProducts, shallowEqual);
    const {rectangularDimension, lShapedDimension} = useAppSelector(
        getRoomDimensions,
        shallowEqual
    );
    const previewPosition = useAppSelector(getPreviewPosition);
    const previewPositionRef = useRef<PreviewPosition>(null);

    const {camera, controls, setupCamera, setupCameraPosition} =
        useCameraSetup();
    const {renderer, setupRenderer} = useRendererSetup();
    const {
        cabinetGroup,
        attachEvents,
        clearEvents,
        getGroupWidth,
        triggerEvents,
    } = useProductEvents(
        renderer,
        mountRef,
        setShowArrows,
        setDragPosition,
        productsRef
    );
    const isWireframeMode = useAppSelector(getIsWireframeMode);

    const {applyDynamicAdjustableLegs} = useAdjustableLegs();
    const selectedProductValue = useAppSelector(getSelectedProductValue);
    const [searchParams] = useSearchParams();

    const cabinetId = searchParams.get('cabinetId');
    const productId = searchParams.get('product');

    const [getProductData] = useLazyGetProductDataQuery();

    const addProductManually = useAddProductManually();

    const {job, room} = useJobContext() as PartialJob;
    const {notify} = useNotificationContext();
    const {addRecentItem} = useRecentlyAddedActions();

    useRoomProduct();

    const {
        createLShapedWalls,
        createLShapedFloor,
        createRectangularFloor,
        createRectangularWalls,
        wallRefs,
        isWireframeModeRef,
    } = useRoomLayout(
        showArrows,
        dragPosition,
        cabinetGroup,
        getGroupWidth,
        cabinetGroupList,
        showCabinets
    );

    const {checkCameraZone} = useWallHider(wallRefs, roomType);
    const {replaceDimensions} = useRoomPlannerData();
    const productValuesForUpdate = useAppSelector(getProductValuesForUpdate);
    const {debouncedSave} = useAutoSave();

    const generateCabinetMesh = async (values: any) => {
        const json = xml2json(values.template_3d[1] as string, {
            compact: false,
        });
        const variables = JSON.parse(
            values.template_3d[0].attributes.variables
        );
        // const template = await loadXMLTemplate(values.template.name);
        const parsedJson = replaceDimensions(json, values, variables);
        const data = loadDataXML(
            json2xml(parsedJson, {compact: false})
        ) as Batch;

        const cabinetGroup = await createSceneXML(
            data,
            values,
            values.template,
            true,
            true,
            false,
            isWireframeModeRef.current
        );

        if (Number(values.cabinet_adjustable_legs) > 0) {
            applyDynamicAdjustableLegs(cabinetGroup, values);
        }

        return cabinetGroup;
    };

    useEffect(() => {
        if (!roomType) return;

        const renderer = setupRenderer(mountRef);
        const cameraLocal = setupCamera(
            mountRef,
            scene.current,
            renderer.current
        );
        setupCameraPosition(previewPositionRef.current);

        // Scene
        scene.current = new THREE.Scene();
        scene.current.background = new THREE.Color(0xffffff);

        // Lights
        const ambientLight = new THREE.AmbientLight(0xffffff, 2);
        scene.current.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.2);
        directionalLight.position.set(500, 1000, 700);
        scene.current.add(directionalLight);

        const textureLoader = new THREE.TextureLoader();
        const isLeftLeveledStaticView =
            previewPositionRef.current === 'LEFT_LEVELED_STATIC';

        if (roomType === 'LSHAPED') {
            createLShapedWalls(
                scene.current,
                lShapedDimension,
                camera,
                renderer,
                isLeftLeveledStaticView
            );
            createLShapedFloor(
                scene.current,
                textureLoader,
                lShapedDimension,
                previewPositionRef.current === 'TOP',
                isLeftLeveledStaticView
            );
        }

        if (roomType === 'RECTANGULAR') {
            createRectangularFloor(
                scene.current,
                textureLoader,
                rectangularDimension,
                previewPositionRef.current === 'TOP',
                isLeftLeveledStaticView
            );
            createRectangularWalls(
                scene.current,
                rectangularDimension,
                camera,
                renderer,
                isLeftLeveledStaticView
            );
        }

        const animate = () => {
            requestAnimationFrame(animate);
            controls.current?.update();

            renderer.current.clear();
            renderer.current.clearDepth();
            renderer.current.render(scene.current, cameraLocal.current);

            if (camera.current) checkCameraZone(camera.current);

            TWEEN.update();
        };
        animate();

        cameraLocal.current.updateProjectionMatrix();

        return () => {
            renderer.current?.dispose();
            mountRef.current?.removeChild(renderer.current.domElement);
        };
    }, [roomType, rectangularDimension, lShapedDimension, reloader]);

    useEffect(() => {
        if (roomType) renderProducts();

        return () => {
            if (roomType) {
                clearEvents();
                disposeCabinets();
            }
        };
    }, [
        roomPlannerData,
        roomType,
        rectangularDimension,
        lShapedDimension,
        reloader,
    ]);

    useEffect(() => {
        setReloader(1);
    }, []);

    useEffect(() => {
        if (previewPosition) {
            previewPositionRef.current = previewPosition;
        }
    }, [previewPosition]);

    const getRoomDimensionsComputed = () => {
        const scale = 0.2;
        return {
            computedWidth:
                ((roomType === 'RECTANGULAR'
                    ? rectangularDimension.width
                    : lShapedDimension.rightWidth) /
                    2) *
                    -scale +
                26.1,
            roomWidth:
                (roomType === 'RECTANGULAR'
                    ? rectangularDimension.width
                    : lShapedDimension.rightWidth) * scale,
            roomDepth:
                roomType === 'RECTANGULAR'
                    ? rectangularDimension.length
                    : lShapedDimension.leftWidth,
            roomDepthComputed:
                ((roomType === 'RECTANGULAR'
                    ? rectangularDimension.length
                    : lShapedDimension.leftWidth) /
                    2) *
                    -scale -
                25,
            wallHeight:
                (roomType === 'RECTANGULAR'
                    ? rectangularDimension.height
                    : lShapedDimension.height) * scale,
            scale,
            roomLeftDepth: lShapedDimension.leftDepth || 0,
            roomRightDepth: lShapedDimension.rightDepth || 0,
            roomLeftWidth: lShapedDimension.leftWidth || 0,
            roomRightWidth: lShapedDimension.rightWidth || 0,
        };
    };

    const renderCabinetGroup = async (product: any, dimensions: any) => {
        const {
            scale,
            wallHeight,
            roomDepthComputed,
            positionX,
            roomLeftDepth,
            roomRightDepth,
            roomLeftWidth,
            roomRightWidth,
            defaultPositionZ,
        } = dimensions;
        const cabinetGroup = await generateCabinetMesh(product);
        const variables = JSON.parse(
            product.template_3d[0].attributes.variables
        );
        const templateName = product.template_3d[0].attributes.template;
        const isFloorProduct = templateName?.includes('KickerFramed');
        const isPanelProduct = templateName?.includes('AppliedPanel');

        const {width, height, depth} = getGroupWidth(cabinetGroup);
        const positionY =
            -(wallHeight / 2) +
            (variables?.isUpperProduct ? 300 : isFloorProduct ? 51 : 73);
        const positionZ =
            defaultPositionZ ?? roomDepthComputed + height * scale + 10;

        cabinetGroup.userData.id = product.duplicate_id
            ? product.duplicate_id
            : product.job_cabinet_id;
        cabinetGroup.userData.values = product;
        cabinetGroup.userData.onHide = () => {
            cabinetGroup.visible = false;
        };

        const directions = ['front', 'left', 'back', 'right'];

        if (isPanelProduct) {
            cabinetGroup.userData.ignoreSnapping = true;
        }

        cabinetGroup.userData.currentDirection = 'front'; // Default facing direction

        cabinetGroup.userData.rotateLeft = () => {
            cabinetGroup.rotation.z += Math.PI / 2;
            cabinetGroup.userData.currentRotation = cabinetGroup.rotation;

            // Update direction
            const currentIndex = directions.indexOf(
                cabinetGroup.userData.currentDirection
            );
            cabinetGroup.userData.currentDirection =
                directions[(currentIndex + 1) % 4];

            debouncedSave(cabinetGroup, roomType);
        };

        cabinetGroup.userData.rotateRight = () => {
            cabinetGroup.rotation.z += -Math.PI / 2;
            cabinetGroup.userData.currentRotation = cabinetGroup.rotation;

            // Update direction
            const currentIndex = directions.indexOf(
                cabinetGroup.userData.currentDirection
            );
            cabinetGroup.userData.currentDirection =
                directions[(currentIndex + 3) % 4]; // +3 is the same as -1 in circular array

            debouncedSave(cabinetGroup, roomType);
        };

        cabinetGroup.userData.duplicateCabinet = async () => {
            try {
                // Get current product data
                const {data: productData} = await getProductData({
                    cabinetType: cabinetGroup.userData.values.type,
                    roomId: cabinetGroup.userData.values.room_id,
                    cabinetId: cabinetGroup.userData.id,
                });

                if (productData) {
                    // Save the new product using addProductManually
                    const {response} = await addProductManually({
                        product: {
                            id: Number(productData.type),
                            room_id: Number(productData.room_id),
                        },
                        room,
                        width: Number(productData.cabinet_width),
                        height: Number(productData.cabinet_height),
                        depth: Number(productData.cabinet_depth),
                    });

                    if (response && response.data) {
                        // Create a new cabinet group for the duplicate
                        const newCabinetGroup = cabinetGroup.clone();

                        // Set up the new cabinet's data
                        newCabinetGroup.userData.id = response.data.id;
                        newCabinetGroup.userData.quantity = 1;
                        newCabinetGroup.userData.values = {
                            ...newCabinetGroup.userData.values,
                            job_cabinet_id: response.data.id,
                            id: response.data.id,
                            duplicate_id: undefined,
                        };

                        // Position the new cabinet next to the original
                        const existingPositions = cabinetGroupList.map(
                            (cabinet) => cabinet.position
                        );

                        let maxX = Math.max(
                            ...existingPositions.map((pos) => pos.x),
                            newCabinetGroup.position.x
                        );
                        newCabinetGroup.position.set(
                            maxX + 150,
                            newCabinetGroup.position.y,
                            newCabinetGroup.position.z
                        );
                        await renderCabinetGroup(
                            newCabinetGroup.userData.values,
                            {
                                ...dimensions,
                                positionX: newCabinetGroup.position.x + 40,
                                defaultPositionZ: newCabinetGroup.position.z,
                            }
                        );
                        // Update recent items and invalidate counts
                        dispatch(invalidateTotalProductCount());
                        dispatch(invalidateSidebarAndCost());
                        addRecentItem(
                            productData.type,
                            RECENTLY_ADDED_KEYS.PRODUCT
                        );
                    }
                }
            } catch (error) {
                console.error('Error duplicating cabinet:', error);
            }
        };

        cabinetGroup.userData.deleteCabinet = () => {
            setCabinetGroupList((prev) =>
                prev.filter((cabinet) => cabinet !== cabinetGroup)
            );
            scene.current.remove(cabinetGroup);
        };

        const storedPosition = localStorage.getItem(
            `cabinet-${roomType}:${cabinetGroup.userData.id}`
        );

        const storedRotation = localStorage.getItem(
            `cabinet-rotation:${cabinetGroup.userData.id}`
        );

        if (storedRotation) {
            cabinetGroup.rotation.copy(JSON.parse(storedRotation));
        }

        if (storedPosition) {
            cabinetGroup.position.copy(JSON.parse(storedPosition));
        } else {
            cabinetGroup.position.x = positionX;
            cabinetGroup.position.z = positionZ;
        }

        cabinetGroup.position.y = positionY;

        cabinetGroup.rotation.x = -(90 * Math.PI) / 180;
        cabinetGroup.scale.set(scale, scale, scale);

        attachEvents({
            scene: scene.current,
            cabinetGroup,
            scale,
            camera,
            controls,
            product,
            computedWidth: positionX + width * scale,
            positionY,
            roomDepth: dimensions.roomDepth * scale,
            roomWidth: dimensions.roomWidth,
            roomType,
            roomLeftDepth,
            roomRightDepth,
            roomLeftWidth,
            roomRightWidth,
        });

        scene.current.add(cabinetGroup);

        setCabinetGroupList((prev) => [...prev, cabinetGroup]);
        productsRef.current?.push(cabinetGroup);

        return {widthIncrement: width * scale, cabinetGroup};
    };

    const disposeCabinets = () => {
        for (const cabinet of productsRef.current) {
            // Remove and cleanup existing cabinet
            scene.current.remove(cabinet);
            cabinet.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.geometry.dispose();
                    if (child.material instanceof THREE.Material) {
                        child.material.dispose();
                    } else if (Array.isArray(child.material)) {
                        child.material.forEach((material) =>
                            material.dispose()
                        );
                    }
                }
            });
        }

        for (const cabinet of cabinetGroupList) {
            // Remove and cleanup existing cabinet
            scene.current.remove(cabinet);
            cabinet.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.geometry.dispose();
                    if (child.material instanceof THREE.Material) {
                        child.material.dispose();
                    } else if (Array.isArray(child.material)) {
                        child.material.forEach((material) =>
                            material.dispose()
                        );
                    }
                }
            });
        }

        setCabinetGroupList([]);
        productsRef.current = [];
    };

    const renderProducts = useDebouncedCallback(async () => {
        if (!roomPlannerData) return;

        const dimensions = getRoomDimensionsComputed();
        let currentWidth = Number(dimensions.computedWidth);

        disposeCabinets();

        for (const product of roomPlannerData) {
            try {
                const {widthIncrement} = await renderCabinetGroup(product, {
                    ...dimensions,
                    positionX: currentWidth + 5,
                });
                currentWidth += widthIncrement;
            } catch (err) {
                alert(err);
            }
        }
    }, 200);

    const updateCabinet = useDebouncedCallback(async () => {
        if (
            productValuesForUpdate &&
            selectedProductValue &&
            cabinetGroupList
        ) {
            const cabinet = cabinetGroupList.find(
                (group) =>
                    group.userData.id === selectedProductValue.job_cabinet_id &&
                    group.userData.id == productValuesForUpdate.job_cabinet_id
            );

            if (!cabinet) return;

            scene.current.remove(cabinet);

            setCabinetGroupList((prev) =>
                prev.filter(
                    (group) =>
                        group.userData.id !==
                        selectedProductValue.job_cabinet_id
                )
            );
            cabinet.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.geometry.dispose();
                    if (child.material instanceof THREE.Material) {
                        child.material.dispose();
                    } else if (Array.isArray(child.material)) {
                        child.material.forEach((material) =>
                            material.dispose()
                        );
                    }
                }
            });

            // Render updated cabinet
            const dimensions = getRoomDimensionsComputed();

            const productWithNewDimensions = {
                ...selectedProductValue,
                ...productValuesForUpdate,
            };

            const {cabinetGroup: updatedCabinetGroup} =
                await renderCabinetGroup(productWithNewDimensions, dimensions);

            updatedCabinetGroup.userData.selected = true;

            dispatch(setProductValuesForUpdate(null));
        }
    }, 200);

    useEffect(() => {
        updateCabinet();
    }, [
        productValuesForUpdate,
        selectedProductValue,
        cabinetGroupList,
        roomPlannerData,
    ]);

    useEffect(() => {
        if (cabinetGroupList) {
            cabinetGroupList.forEach((cabinet) => {
                cabinet.visible = showCabinets;
            });
        }
    }, [showCabinets, cabinetGroupList]);

    useEffect(() => {
        if (cabinetId && productId && cabinetGroupList) {
            const cabinet = cabinetGroupList.find(
                (group) => group.userData.id == cabinetId
            );

            if (!cabinet) return;

            dispatch(setSelectedProductValue(cabinet.userData.values));
        }
    }, [cabinetId, productId, cabinetGroupList]);

    useEffect(() => {
        if (productsRef.current) {
            productsRef.current.forEach((product) => {
                triggerEvents(product, 'onToggleWireframe', isWireframeMode);
            });
        }

        if (wallRefs.current) {
            wallRefs.current.forEach((wall) => {
                triggerEvents(wall, 'onToggleWireframe', isWireframeMode);
            });
        }
    }, [isWireframeMode]);

    return {
        cabinetGroupList,
        mountRef,
        camera,
        scene,
        renderer,
        cabinetGroup,
    };
};

export default useRoomPlanner;
