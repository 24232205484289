import * as THREE from 'three';
import {Batch, Edge, PreviewFormValues} from 'Preview3D/types';
import {createPanelGeometry} from 'components/customer/Preview3D/helpers';
import TWEEN from '@tweenjs/tween.js';
import {RoomPlannerProduct} from 'components/customer/RoomPlanner/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';

export const createSceneXML = async (
    batch: Batch,
    values: RoomPlannerProduct,
    variables: TemplateVariable,
    showTexture?: boolean,
    includeDrawerFaces?: boolean,
    showShelfLabel?: boolean,
    isWireframeMode?: boolean
) => {
    const textureLoader = new THREE.TextureLoader();
    const substrateTextureTemp = await textureLoader.loadAsync(
        `/templates/3D/textures/MDF.jpg`
    );

    if (typeof batch === 'undefined' || batch == null) return;

    const cabinetGroup = new THREE.Group();
    const previewValues = {...values} as unknown as PreviewFormValues;
    const edging: Edge | null =
        typeof previewValues.cabinet_edge_l1 !== 'undefined' ||
        typeof previewValues.panel_edge_top !== 'undefined'
            ? {
                  l1:
                      Boolean(
                          parseInt(previewValues.cabinet_edge_l1 as string)
                      ) ||
                      Boolean(
                          parseInt(previewValues.panel_edge_left as string)
                      ),
                  l2:
                      Boolean(
                          parseInt(previewValues.cabinet_edge_l2 as string)
                      ) ||
                      Boolean(
                          parseInt(previewValues.panel_edge_right as string)
                      ),
                  w1:
                      Boolean(
                          parseInt(previewValues.cabinet_edge_w1 as string)
                      ) ||
                      Boolean(parseInt(previewValues.panel_edge_top as string)),
                  w2:
                      Boolean(
                          parseInt(previewValues.cabinet_edge_w2 as string)
                      ) ||
                      Boolean(
                          parseInt(previewValues.panel_edge_bottom as string)
                      ),
              }
            : null;

    for (const job of Object.values(batch.Jobs)) {
        for (const manufacturing of Object.values(job.Manufacturing)) {
            const operationGroups = Object.values(
                manufacturing.OperationGroups
            );

            for (const operationGroup of operationGroups) {
                const part = Object.values(job.Items)[0].Parts[
                    operationGroup.PartID
                ];

                if (!includeDrawerFaces && operationGroup.ExteriorMaterial) {
                    continue;
                }

                if (
                    (operationGroup.ExteriorMaterial &&
                        values.ext_material?.brand_name === 'Non Supply') ||
                    (operationGroup.CarcaseMaterial &&
                        values.carc_material?.brand_name === 'Non Supply')
                ) {
                    continue;
                }

                const mesh = createPanelGeometry(
                    part,
                    operationGroup,
                    '#000',
                    showTexture,
                    values.cabinet_ext_image
                        ? values.cabinet_ext_image
                        : `/uploads/gocabinet_materials/${values.ext_material?.image}`,
                    values.cabinet_carc_image
                        ? values.cabinet_carc_image
                        : `/uploads/gocabinet_materials/${values.carc_material?.image}`,
                    values.cabinet_ext_edge_image
                        ? values.cabinet_ext_edge_image
                        : `/uploads/gocabinet_materials/${values.ext_edge?.image}`,
                    values.cabinet_carc_edge_image
                        ? values.cabinet_carc_edge_image
                        : `/uploads/gocabinet_materials/${values.carc_edge?.image}`,
                    true,
                    Boolean(operationGroup.ExteriorMaterial) ||
                        Boolean(operationGroup.CarcaseMaterial)
                        ? operationGroup.ExteriorMaterial?.toString() === 'true'
                        : null,
                    true,
                    true,
                    edging, // edging
                    false,
                    operationGroup.IsDrawerRunner ||
                        (operationGroup.IsDrawerFront &&
                            Boolean(variables?.innerDrawerCount)),
                    {
                        exteriorThickness: 16.5,
                        carcaseThickness: 16.5,
                    },
                    false,
                    false,
                    operationGroup?.ShelfLabel,
                    showShelfLabel,
                    new THREE.MeshStandardMaterial({
                        map: substrateTextureTemp,
                    }), // carcaseSubstrateMaterial
                    new THREE.MeshStandardMaterial({
                        map: substrateTextureTemp,
                    }), // exteriorSubstrateMaterial
                    true,
                    variables?.isLeftReturn,
                    variables?.isReturnProduct && !variables?.isLeftReturn,
                    operationGroups?.length,
                    variables?.isUpperProduct || variables?.isApplianceProduct,
                    Boolean(operationGroup.NoEdging),
                    operationGroup.IsRightDoor ||
                        operationGroup.IsPairDoor ||
                        operationGroup.IsLeftDoor ||
                        operationGroup.IsRightDoor ||
                        operationGroup.IsDoorPullout ||
                        operationGroup.IsTopHangDoor ||
                        operationGroup.IsBifoldLeftDoor ||
                        operationGroup.IsBifoldLeftDoor ||
                        operationGroup.IsBifoldRightDoor ||
                        operationGroup.IsCornerLeftDoor ||
                        operationGroup.IsCornerRightDoor ||
                        operationGroup.IsDrawerFront ||
                        operationGroup.WithEdging ||
                        operationGroup.IsLeftApplianceMainDoor ||
                        operationGroup.IsLeftApplianceExtendedDoor ||
                        operationGroup.IsRightApplianceMainDoor ||
                        operationGroup.IsRightApplianceExtendedDoor ||
                        (operationGroup.ExteriorMaterial &&
                            variables?.isReturnProduct),
                    Boolean(operationGroup.FlipSubstrate),
                    Boolean(operationGroup.IsFrontRangehoodVent),
                    Boolean(operationGroup.WithTopEdging),
                    Boolean(operationGroup.WithFrontEdging),
                    Boolean(operationGroup.DynamicExteriorEdging),
                    isWireframeMode
                );
                const boundingBox = new THREE.Box3().setFromObject(mesh);
                const meshPos = operationGroup.Operations[0].Insert;
                const meshRot = operationGroup.Operations[0].Rotation;
                const meshNormal =
                    operationGroup.Operations[0].Segments[0].StartNormal;

                const matrixRot = new THREE.Matrix4();

                matrixRot.makeRotationAxis(
                    new THREE.Vector3(
                        -meshNormal.x,
                        -meshNormal.y,
                        -meshNormal.z
                    ),
                    THREE.MathUtils.degToRad(meshRot + 90)
                );

                const matrix = new THREE.Matrix4();

                const up =
                    meshNormal.z == 0
                        ? new THREE.Vector3(0, 0, 1)
                        : new THREE.Vector3(0, 1, 0);

                if (
                    operationGroup.MfgOrientationID !== undefined &&
                    meshNormal.z != 0
                ) {
                    up.negate();
                }

                matrix.lookAt(
                    new THREE.Vector3(0, 0, 0),
                    new THREE.Vector3(meshNormal.x, meshNormal.y, meshNormal.z),
                    up
                );

                matrixRot.multiply(matrix);

                matrixRot.setPosition(
                    new THREE.Vector3(meshPos.x, meshPos.y, meshPos.z)
                );

                mesh.applyMatrix4(matrixRot);

                if (operationGroup.IsBifoldLeftDoor) {
                    mesh.rotation.y = (90 * Math.PI) / 180;
                }

                if (
                    operationGroup.IsCornerLeftDoor ||
                    operationGroup.IsCornerRightDoor
                ) {
                    mesh.rotation.y = (45 * Math.PI) / 180;
                }

                if (operationGroup.FlipY) {
                    mesh.rotation.y = (180 * Math.PI) / 180;
                }

                if (operationGroup.IsLeftDoor) {
                    mesh.userData.toggleDoor = () => {
                        const radians = (60 * Math.PI) / 180;

                        mesh.userData.isDoorOpen = mesh.userData.isDoorOpen
                            ? !mesh.userData.isDoorOpen
                            : true;

                        const size = new THREE.Vector3();
                        boundingBox.getSize(size);

                        mesh.position.x = 0;
                        mesh.scale.x = -1;
                        const initialRotation = {y: 0};
                        const openRotation = {y: -radians};
                        const closeRotation = {y: 0};

                        if (mesh.userData.isDoorOpen) {
                            new TWEEN.Tween(initialRotation)
                                .to(openRotation, 1000)
                                .easing(TWEEN.Easing.Quadratic.Out)
                                .onUpdate(() => {
                                    mesh.rotation.y = initialRotation.y;
                                })
                                .start();
                        } else {
                            new TWEEN.Tween(initialRotation)
                                .to(closeRotation, 1000)
                                .easing(TWEEN.Easing.Quadratic.Out)
                                .onUpdate(() => {
                                    mesh.rotation.y = initialRotation.y;
                                })
                                .start();
                        }
                    };
                }

                if (operationGroup.IsRightDoor) {
                    mesh.userData.toggleDoor = (data: {doorGap: number}) => {
                        const radians = (60 * Math.PI) / 180;

                        mesh.userData.isDoorOpen = mesh.userData.isDoorOpen
                            ? !mesh.userData.isDoorOpen
                            : true;
                        // toggleDoorHandler(mesh, true, false, true);

                        const size = new THREE.Vector3();
                        boundingBox.getSize(size);
                        const width = size.x;
                        mesh.position.x = width * 2 + data.doorGap;
                        mesh.scale.x = -1;
                        const initialRotation = {y: 0};
                        const openRotation = {y: radians};
                        const closeRotation = {y: 0};

                        if (mesh.userData.isDoorOpen) {
                            new TWEEN.Tween(initialRotation)
                                .to(openRotation, 1000)
                                .easing(TWEEN.Easing.Quadratic.Out)
                                .onUpdate(() => {
                                    mesh.rotation.y = initialRotation.y;
                                })
                                .start();
                        } else {
                            new TWEEN.Tween(initialRotation)
                                .to(closeRotation, 1000)
                                .easing(TWEEN.Easing.Quadratic.Out)
                                .onUpdate(() => {
                                    mesh.rotation.y = initialRotation.y;
                                })
                                .start();
                        }
                    };
                }

                cabinetGroup.add(mesh);
            }
        }
    }

    const box = new THREE.Box3().setFromObject(cabinetGroup);
    const cabinetSize = box.getSize(new THREE.Vector3());
    cabinetGroup.position.set(
        -box.min.x - cabinetSize.x / 2,
        -box.min.y - cabinetSize.y / 2,
        0
    );

    return cabinetGroup;
};
